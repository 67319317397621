.platform-wrapper {
    background-color: #FFF;
}

.platform-card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 50px;
}

.platform-img {
    height: 70vh;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 6px;
}

.platform-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5%;
}

.platform-h3 {
    color:#202020;
}

.platform-list-item {
    color: #a90000;
}

span {
    color: black;
}

h4, .platform-h3 {
    margin-bottom: -10px;
}


@media screen and (max-width: 600px) {
    .platform-card {
        flex-wrap: wrap;
    }

    .platform-img {
        height: 40vh;
    }

    .platform-h1 {
        margin-top: 40px;
    }

    .platform-list {
        font-size: 0.75rem;
    }

    
}