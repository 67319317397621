.home {
    display: flex;
  }

  .bg-image {
    background-image: 
    linear-gradient(to top, transparent 0%, #000 100%),
    url('../images/home-background.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    height: 100vh;
  }

  .landing {
    display: flex;
    justify-content: center;
    color: #ffffff;
    position: relative;
    top: 30%;
    font-family: 'Noto Sans' sans-serif;
    font-size: 2rem;
  }

  .home-button {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    justify-content: center;
    margin-top: 300px;
  }

  .homeBtn {
    height: 50px;
    width: 175px;
    background-color: #a90000;
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 4px;
    border: 1px solid #a90000;
    cursor: pointer;
    margin: 40px;
  }

  .homeBtn:hover {
    background-color: #990000
  }



  @media screen and (max-width: 600px) {
    .landing {
      font-size: 1.25rem;
      text-align: center;
    }

  
    .homeBtn {
      width: 125px;
      height: 40px;
      font-size: 0.80rem;
      margin: 20px;
    }
   

  }

  @media screen and (min-width: 600px) and (max-width: 1023px) {
    .landing {
      font-size: 1.75rem;
      text-align: center;
    }

  }

    @media screen and (max-width: 360px) {
      .home-button {
        display: none;
      }
    }


    @media screen and (max-width: 900px) {
      .bg-image {
        background-image: 
    linear-gradient(to top, transparent 0%, #000 100%),
    url('../images/home-background-res.png');
      }
    }