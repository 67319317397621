
.contact {
    background: linear-gradient(to top, transparent 0%, #000 90%)
}

.contact-para-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 75px;
}


.ch1 {
    display: flex;
    justify-content: center;
    color: #ffffff;
}

.contact-h1 {
    font-size: 3rem;
}

.icon {
    color: #a90000;
    font-size: 4.5rem;
    padding-left: 15px;
    padding-right: 15px;
}

.contact-para {
    color: #ffffff;
    font-size: 1.2rem;
}

.wrapper {
     margin: 0;
     padding: 0;
     box-sizing: border-box;
     outline: none;
     position: absolute;
     top: 70%;
     transform: translateY(-50%);
     width: 100%;
     padding: 0 20px;
}

.contact-form {
    max-width: 600px;
    margin: 0 auto;
    background: rgba(22,22,22);
    padding: 30px;
    border-radius: 5px;
    display: flex;
    box-shadow: 0 0 10px rgba(0,0,0,0.8);
    margin-top: 25px;
}

.input-fields {
    display: flex;
    flex-direction: column;
    margin-right: 4%;
}

.input-fields, .msg {
    width: 48%;
}

.input-fields .input, .msg textarea {
    margin: 10px 0;
    background: transparent;
    border: 0;
    border-bottom: 2px solid #ffffff;
    padding: 10px;
    color: #ffffff;
    width: 100%;
}

.msg textarea {
    height: 139px;
}

::-webkit-input-placeholder {
    color: #ffffff;
    font-family: 'Noto Sans', sans-serif;
}

::-moz-input-placeholder {
    color: #ffffff;
    font-family: 'Noto Sans', sans-serif;
}

::-ms-input-placeholder {
    color: #ffffff;
    font-family: 'Noto Sans', sans-serif;
}

.submit-button {
    background-color: transparent;
    outline: none;
    border: none;
    text-decoration: underline;
    text-underline-position: below;
    text-underline-offset: 0.5em;
    text-decoration-thickness: 2px;
    text-decoration-color: #ffffff;
    text-transform: uppercase;
    padding: 10px;
    color: #ffffff;
    cursor: pointer;
    font-size: 1rem;
  }

  .submit-button:hover {
      color: #a90000;
      text-decoration-color: #a90000;
  }

  .Alert.error, .Alert.success {
    background-color: #a90000;
    color: white;
}

.popupButton {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.modal {
    font-size: 20px;
  }

  .modal-header {
      text-align: center;
  }
 
  .modal > .content {
    width: 100%;
    padding: 10px 5px;
    text-align: center;
  }
  
  .modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }


  @media screen and (max-width: 600px) {
      .contact-form {
          flex-direction: column;
          max-width: 300px;
      }

      .contact-page {
        overflow: scroll;
      }

      .wrapper {
          padding: 5px;
          width: 95%;
          top: 70%;
      }

      .msg textarea {
          height: 40px;
      }

      .input-fields, .msg {
          width: 100%;
      }

      .input-fields .input, .msg textarea {
        border-bottom: 0.1px solid #ffffff;
      }

      .submit-button {
          font-size: 0.75rem;
      }

      .ch1 {
          display: none;
      }

      .contact-para-wrapper {
          margin-top: 0;
      }

      .modal {
        font-size: 15px;
      }

      .icon {
        font-size: 3rem;
        padding-top: 60px;
      }

  }

  


