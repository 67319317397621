.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 50px;
    height: 90px;
    border-bottom: 3px solid #a90000;
}

.logo-nav {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-container a {
    list-style-type: none;
    text-decoration: none;
}

.nav-options {
    padding-left: 25px;
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 50px;
    list-style-type: none;
    position: absolute;
    right: 50px;
}

.mobile-menu {
    display: none;
}

li a {
    list-style-type: none;
    text-decoration: none;
    color: #ffffff;
    font-weight: bold;
    font-size: 1.25rem;
}

.option :hover {
    text-decoration: underline;
    text-decoration-color: #a90000;
    text-decoration-thickness: 5px;
    text-underline-offset: 10px;
}

@media screen and (max-width: 800px) {

    .logo {
        width: 125px;
    }

    .header {
        padding: 0px 10px;
    }

    .nav-options {
        display: flex;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 77px;
        left: -100%;
        opacity: 0;
        transition: all 0.5s ease;
        flex-direction: column;
        list-style-type: none;
        grid-gap: 0px;
    }

    .nav-options.active {
        background: #202020;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        align-content: center;
        padding-left: 0px;
    }

    .menu-icon {
        width: 45px;
        height: 45px;
        color: #ffffff;
    }

    .option {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 10vw;
        padding: 10px 0px;
    }

    .mobile-menu {
        display: block;
    }
}

@media screen and (min-width: 801px) and (max-width: 900px) {
    .logo {
        width: 175px;
    }
 }