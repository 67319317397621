.about {
    background-image: linear-gradient(to top, transparent 0%, #000 100%), 
    url('../images/tracker.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 50vh;
}

.about-wrapper {
   position: relative;
   top: 80%;
   height: 100%;
   background-color: #ffffff;
   padding: 10px;
}

.cardImg {
    height: 150px;
}

.cards {
    display: flex;
    justify-content: space-around;
}

.card1, .card2, .card3 {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    width: 400px;
    height: 375px;
    margin: auto;
    background-color: #ffffff;
    padding: 10px;
  }

@media screen and (max-width: 600px) {
    body {
        overflow: auto;
    }

    .about {
        height: 35vh;
    }

    .about-wrapper {
        background-color: #ffffff;
        height: auto;
    }

    .cards {
        flex-direction: column;
    }

    h1 {    
        font-size: 1.25rem;
    }

    .card1, .card2, .card3 {
        width: 300px;
        height: 300px;
        margin-top: 5px;
    }

    .info-icon {
        font-size: 1.5rem;
    }

    .about-para, .info {
        font-size: 0.75rem;
    }
}

@media screen and (min-width: 601px) and (max-width: 1020px) {
    .card1, .card2, .card3 {
        height: 475px;

    }

}

@media screen and (min-width: 1021px) and (max-width: 1400px) {
    .card1, .card2, .card3 {
        height: 500px;

    }

}
